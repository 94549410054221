import { getLogger } from '#api/logger-with-context'

export class NotFoundError {
  constructor(message) {
    this.message = message
  }
}

export class InputError {
  constructor(message) {
    this.message = message
  }
}

export class BadResponseError {
  constructor(message) {
    this.message = message
  }
}

export function errorResponse(res, err) {
  if (err instanceof InputError) {
    res.status(400)
  } else if (err instanceof NotFoundError) {
    res.status(404)
  } else if (err instanceof BadResponseError) {
    res.status(502)
  } else {
    res.status(500)
  }

  res.json({ error: err.message })
}

export const logRejection = async promise => {
  const log = getLogger()
  try {
    return await promise
  } catch (err) {
    log.error(err.message)
    throw err
  }
}
