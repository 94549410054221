import getClient from './client'
import { getLogger } from '#api/logger-with-context'

export const getSecret = async secretName => {
  const log = getLogger()
  log.info({ secretName }, 'getSecret start')
  if (process.env[secretName]) {
    log.info({ secretName }, 'getSecret success')
    return process.env[secretName]
  }
  try {
    const client = getClient()
    const name = `${process.env.GOOGLE_CLOUD_PROJECT_ID}/secrets/${secretName}/versions/latest`
    const accessResponse = await client.accessSecretVersion({ name })
    const secretValue = accessResponse[0].payload.data.toString('utf8')

    process.env[secretName] = secretValue

    log.info({ secretName }, 'getSecret success')
    return secretValue
  } catch (error) {
    log.error({ error, secretName }, 'Error in #getSecret')
  }
}
