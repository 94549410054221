import isNil from 'ramda/src/isNil'
import { InputError } from './errors'

const FALSY_VALUES = Object.freeze(['', 'undefined', 'null'])

export const isNilOrEmptyString = v => isNil(v) || FALSY_VALUES.includes(v)

export const failIf = (value, predicate, message) => {
  if (predicate(value)) {
    throw new InputError(message)
  }
}
