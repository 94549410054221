import { shouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill'
import { fetchNavigationContent } from '#lib/get-site-navigation'
import { getMappedSettings } from '#lib/get-site-settings'
import { removeUndefinedProperties } from '#lib/object'
import { mapLocale, normaliseLocale } from '#lib/locale'
import { getLaunchDarklyFlag } from '#api/flags'
import locales from '#src/../locales'
import { getBagIdFromCtx } from '#lib/get-bag-id'
import { getAnonymousIdFromCtx } from '#lib/provide-anonymous-id'
import { getMarketFromCtx, getMarketDetailsFromLocale } from '#lib/get-market'
import { fallbackLocaleCode, auth0Flag } from '#constants'

const getCurrentLocale = locale =>
  locales.some(l => mapLocale(l.code) === normaliseLocale(locale))
    ? locale
    : fallbackLocaleCode

const getCommonPageProps = async (locale, path = '/') => {
  if (shouldPolyfill()) {
    await import('@formatjs/intl-relativetimeformat/polyfill')
  }

  if (Intl.RelativeTimeFormat.polyfilled) {
    const importPath = `@formatjs/intl-relativetimeformat/locale-data/${locale.substring(
      0,
      2
    )}`
    await import('' + importPath)
  }

  const currentLocale = getCurrentLocale(locale)
  const navigation = fetchNavigationContent(path, currentLocale)
  return removeUndefinedProperties({
    authApiHost: process.env.AUTH0_AUDIENCE,
    gtmHost: process.env.GTM_HOST,
    gtmId: process.env.GTM_ID,
    isTransactional: false,
    marketDetails: getMarketDetailsFromLocale(locale),
    navigation,
    settings: getMappedSettings(currentLocale),
    theme: process.env.CSS_THEME
  })
}

const getAccountsActiveState = async (auth0Flag, anonymousId, market) => {
  const userAccountsStatus = market?.userAccountsStatus
  if (userAccountsStatus === 'live') {
    return true
  }
  if (userAccountsStatus === 'feature-flagged') {
    const result = await getLaunchDarklyFlag(auth0Flag, anonymousId, market.id)
    return result
  }
  return false
}

export const getTransactionalPageProps = async (ctx, path) => {
  const { locale } = ctx
  const bagId = getBagIdFromCtx(ctx)
  const anonymousId = getAnonymousIdFromCtx(ctx)
  const market = getMarketFromCtx(ctx)

  const [commonProps, isAuth0Active] = await Promise.all([
    getCommonPageProps(locale, path),
    getAccountsActiveState(auth0Flag, anonymousId, market)
  ])

  const url = path.split('/')
  const showOrderSlotBanner =
    bagId &&
    !url.some(page =>
      ['checkout', 'shop-finder', 'outposts', 'outpost'].includes(page)
    )
  const showLoyaltyBanner =
    bagId && url.some(page => ['products'].includes(page))

  return removeUndefinedProperties({
    ...commonProps,
    isTransactional: true,
    showOrderSlotBanner,
    showLoyaltyBanner,
    isAuth0Active
  })
}
export const getYourPretPageProps = async (ctx, path) => {
  const { locale } = ctx
  const anonymousId = getAnonymousIdFromCtx(ctx)
  const market = getMarketFromCtx(ctx)
  const [commonProps, isAuth0Active] = await Promise.all([
    getCommonPageProps(locale, path),
    getAccountsActiveState(auth0Flag, anonymousId, market)
  ])

  return {
    props: removeUndefinedProperties({
      ...commonProps,
      locale,
      isYourPret: true,
      isAuth0Active
    })
  }
}

export const getCustomPageProps = locale => {
  const currentLocale = getCurrentLocale(locale)
  return removeUndefinedProperties({
    customPage: true,
    settings: getMappedSettings(currentLocale),
    gtmId: process.env.GTM_ID,
    gtmHost: process.env.GTM_HOST,
    marketDetails: getMarketDetailsFromLocale(locale)
  })
}

export default getCommonPageProps
