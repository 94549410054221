import getNextPath from '#lib/get-path'
import { mapLocale } from '#lib/locale'
import { fallbackLocaleCode } from '#constants'

const getLinkDetails = (currentPage, item, locale = fallbackLocaleCode) => {
  const { link } = item
  const { type } = link
  switch (type) {
    case 'internalLink':
      return {
        ...link,
        path: getNextPath(link.url, locale),
        active: currentPage === link.url
      }
    case 'page': {
      return {
        ...link,
        active: currentPage === link.url
      }
    }
    default:
      return { ...link }
  }
}

export const getLink = (currentPage, item, locale = fallbackLocaleCode) => {
  if (item.link) {
    item.link = getLinkDetails(currentPage, item, locale)
  }
  if (item.subMenu && item.subMenu.length > 0) {
    item.subMenu = item.subMenu.map(record => ({
      ...record,
      link: getLinkDetails(currentPage, record, locale)
    }))
  }
  return item
}

export const fetchNavigationContent = (currentPage, locale) => {
  const navigation = require(`../../../.navigation/${mapLocale(locale, true)}`)
  Object.keys(navigation).forEach(navigationType => {
    navigation[navigationType] = navigation[navigationType].map(item =>
      getLink(currentPage, item, locale)
    )
  })
  return navigation
}
