import { getLogger } from '#api/logger-with-context'
import { createCircuit } from './circuit-factory'

const circuits = new Map()

export const getCircuit = (circuitName, func) => {
  let circuit = circuits.get(circuitName)
  if (!circuit) {
    const log = getLogger()
    log.debug({ circuitName }, 'CircuitBreaker: creating circuit')

    const envName = convertCircuitName(circuitName)

    const options = {
      name: circuitName,
      timeout: getTimeout(circuitName, envName), // If our function takes longer than this in ms, trigger a failure
      errorThresholdPercentage: getErrorThreshold(circuitName, envName), // When this % of requests fail, trip the circuit
      resetTimeout: getResetTimeout(circuitName, envName) // After this in ms, try again.
    }

    circuit = createCircuit(func, options)

    circuit.on('fire', () => log.debug({ circuitName }, 'CircuitBreaker: fire'))
    circuit.on('success', () =>
      log.debug({ circuitName }, 'CircuitBreaker: success')
    )
    circuit.on('failure', error =>
      log.error({ error, circuitName }, 'CircuitBreaker: failure')
    )
    circuit.on('timeout', () =>
      log.error({ circuitName }, 'CircuitBreaker: timeout')
    )
    circuit.on('reject', () =>
      log.error({ circuitName }, 'CircuitBreaker: reject')
    )
    circuit.on('open', () => log.error({ circuitName }, 'CircuitBreaker: open'))
    circuit.on('halfOpen', () =>
      log.warn({ circuitName }, 'CircuitBreaker: halfOpen')
    )
    circuit.on('close', () =>
      log.debug({ circuitName }, 'CircuitBreaker: close')
    )
    circuit.on('fallback', () =>
      log.error({ circuitName }, 'CircuitBreaker: fallback')
    )

    circuits.set(circuitName, circuit)
  }
  return circuit
}

const convertCircuitName = circuitName =>
  circuitName.toUpperCase().replace(/-/g, '_')

const defaultTimeout = 30000
const getTimeout = (circuitName, envName) => {
  const envParam = `${envName}_CIRCUIT_TIMEOUT`
  let timeout = process.env[envParam]
  if (!timeout) {
    const log = getLogger()
    log.warn(
      {
        envParam,
        defaultTimeout,
        circuitName
      },
      'CircuitBreaker: timeout not set for circuit using defaultTimeout'
    )
    timeout = defaultTimeout
  }
  return timeout
}

const defaultErrorThreshold = 50
const getErrorThreshold = (circuitName, envName) => {
  const envParam = `${envName}_CIRCUIT_ERROR_THRESHOLD`
  let errorThreshold = process.env[envParam]
  if (!errorThreshold) {
    const log = getLogger()
    log.warn(
      {
        envParam,
        defaultErrorThreshold,
        circuitName
      },
      'CircuitBreaker: errorThreshold not set for circuit using defaultErrorThreshold'
    )
    errorThreshold = defaultErrorThreshold
  }
  return errorThreshold
}

const defaultResetTimeout = 5000
const getResetTimeout = (circuitName, envName) => {
  const envParam = `${envName}_CIRCUIT_RESET_TIMEOUT`
  let resetTimeout = process.env[envParam]
  if (!resetTimeout) {
    const log = getLogger()
    log.warn(
      {
        envParam,
        defaultResetTimeout,
        circuitName
      },
      'CircuitBreaker: resetTimeout not set for circuit using defaultResetTimeout'
    )
    resetTimeout = defaultResetTimeout
  }
  return resetTimeout
}
