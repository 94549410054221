import { SecretManagerServiceClient } from '@google-cloud/secret-manager'

let client

const getClient = () => {
  if (!client) {
    client = new SecretManagerServiceClient({
      projectId: process.env.GOOGLE_CLOUD_PROJECT_ID
    })
  }

  return client
}

export default getClient
