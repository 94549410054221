import { failIf, isNilOrEmptyString } from '../validation'
import { getLogger } from '#api/logger-with-context'
import { getClient } from './client-manager'
import { getCircuit } from '#api/circuit-breaker/circuit-manager'
import { anonymousIdHeader } from '#constants'
import { errorResponse } from '../errors'

const circuitName = 'launch-darkly'

export const getLaunchDarklyFlag = async (name, user, country) => {
  const log = getLogger()
  try {
    failIf(name, isNilOrEmptyString, 'missing flag name')
    return getCircuit(circuitName, internalGetLaunchDarklyFlag).fire(
      name,
      user,
      country
    )
  } catch (error) {
    log.error({ error }, 'Error in #getLaunchDarklyFlag')
  }
}

export const internalGetLaunchDarklyFlag = async (name, user, country) => {
  const pretUser = {
    key: user || 'pret-web-server',
    anonymous: !user,
    custom: {
      platform: 'web'
    },
    ...(country ? { country } : {})
  }
  const client = await getClient()
  return client.variation(name, pretUser, false)
}

const getFlag = async (req, res) => {
  const logRef = 'Feature Flag::'
  const log = getLogger()
  const { method, headers, body } = req

  const { name, country } = body
  if (method === 'POST') {
    try {
      log.info({ request: { name } }, `${logRef} get start`)
      const anonymousId = headers[anonymousIdHeader]
      failIf(name, isNilOrEmptyString, 'missing feature flag name')
      const result = await getLaunchDarklyFlag(name, anonymousId, country)
      log.info({ [name]: result }, `${logRef} get end`)
      return res.status(200).json(result)
    } catch (error) {
      log.error({ error, name }, `${logRef} get`)
      errorResponse(res, error)
    }
  } else {
    res.status(405)
    res.end()
  }
}

export default getFlag
