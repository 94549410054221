import { getLogger } from '#api/logger-with-context'
import { getSecret } from '#api/secrets'
import { createClient } from './client-factory'

let client

export const getClient = async () => {
  const log = getLogger()
  try {
    if (!client) {
      const key = await getSecret('LAUNCH_DARKLY_SDK_KEY')
      log.info('#generateClient started')
      client = await createClient(key)
      log.info('#generateClient successfully generated client')
    }
    return client
  } catch (error) {
    log.error({ error }, 'Error in #generateClient')
  }
}
